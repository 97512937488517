body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 100vw;
  height: 100vh;
}

.login form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login form input {
  margin-top: 10px;
}

.login form button {
  margin-top: 10px;
  cursor: pointer;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App table {
  border-collapse: collapse;
  width: 100%;
}

.App table th {
  font-weight: 700;
}

.App table th,
.App table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.wrapper {
  padding: 20px;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wrapper form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.wrapper form input {
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.wrapper form button {
  padding: 10px 20px;
  background-color: #2f85e0;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.wrapper_invoice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 10px 0px 50px 0px;
  width: 100vw;
  height: 100vh;
}

.inner_invoice {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.invoice_block {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.invoice_block h1 {
  font-size: 1rem;
  font-weight: 400;
}

.invoice_block img.logo {
  width: 50%;
  height: auto;
}

.invoice_title {
  font-size: 24px;
  font-weight: 600;
}

.btn-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 90%;
  max-width: 400px;
}

.btn-st {
  display: inline-block;
  padding: 10px 20px;
  background-color: #2f85e0;
  color: #fff;
  border-radius: 15px;
  cursor: pointer;
  width: 100%;
}

.outline-invoice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 10px 0px 20px 0px;
}

@media (max-width: 768px) {
  .invoice_block img.logo {
    width: 55%;
  }

  .outline-invoice {
    padding-top: 40px;
  }
}

@media (min-width: 1025px) {
  .outline-invoice {
    position: relative;
    padding-top: 20px;
    padding-bottom: 50px;
    z-index: 1;
  }

  .outline-invoice::after {
    content: "";
    position: absolute;
    right: 50%;
    top: calc(50% - 10px);
    transform: translate(50%, -50%);
    width: 45%;
    height: 96%;
    background: #ffffff;
    z-index: -1;
    border-radius: 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  body {
    background: #f4f4f4;
  }
}
